@import 'antd.css';
@import 'responsive';
@import 'variables.css';
@import 'typography.scss';
@import 'spacing.scss';

@import "../../node_modules/@syncfusion/ej2-base/styles/material.css";
@import "../../node_modules/@syncfusion/ej2-buttons/styles/material.css";
@import "../../node_modules/@syncfusion/ej2-calendars/styles/material.css";
@import "../../node_modules/@syncfusion/ej2-dropdowns/styles/material.css";
@import "../../node_modules/@syncfusion/ej2-inputs/styles/material.css";
@import "../../node_modules/@syncfusion/ej2-navigations/styles/material.css";
@import "../../node_modules/@syncfusion/ej2-popups/styles/material.css";
@import "../../node_modules/@syncfusion/ej2-react-schedule/styles/material.css";
@import 'syncfusion.scss';

body {
  margin: 0 !important;
  font-family: 'Inter', sans-serif;
  background-color: var(--main-background);
}

.application-layout {
  display: flex;
  flex-direction: column;
  height: 100%;

  &__content {
    display: flex;
    flex-direction: column;
    padding-top: 64px;
    margin-left: 100px;
    background-color: var(--background);
    height: calc(100vh - 64px);

    &[data-guest='true'] {
      margin: 0 !important;
      width: 100%;
    }

    @include device(extraMediumSmall) {
      margin: 32px 0 0 0 !important;
      height: calc(100vh - 100px);
    }

    &-container {
      background-color: var(--background);
      padding: 32px;
      flex: 1;

      @include device(extraMediumSmall) {
        padding: 16px;
      }
    }
  }
}

.whole-page-wrapper {
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.content-page-wrapper {
  width: calc(100vw - 160px);
  height: calc(100vh - 130px);
  display: flex;
  justify-content: center;
  align-items: center;

  @include device(extraMediumSmall) {
    width: calc(100vw - 50px);
  }
}

.cart-inform {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: var(--tertiary-100);
  margin: 0 0 0 0;
  white-space: nowrap;
}

.coming-layout {
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--light-01);
}

::-webkit-scrollbar {
  width: 20px;
  height: 20px;
}

::-webkit-scrollbar-track {
  background: transparent;
}

::-webkit-scrollbar-thumb {
  background: var(--quaternary-80);
}

.select-placeholder {
  img {
    margin: 0 8px 0 0;
  }
}

button {
  border: none;
  text-align: center;
  padding: 0;
  background-color: transparent;
}

a {
  text-decoration: none;
}

hr {
  width: 100%;
  opacity: 0.5;
  border-style: unset;
  border-width: 1px;
  border-top: 1px solid var(--light-03);
  margin: 0;
}

.signatureCanvas {
  border-radius: 6px;
  border: 1px solid var(--light-04);
  box-sizing: border-box !important;
  background-color: var(--light-01);
}

.rpv-default-layout__sidebar-headers,
.rpv-default-layout__container {
  border: none !important;
}

.rpv-default-layout__sidebar,
.rpv-default-layout__toolbar {
  display: none !important;
}

.rpv-default-layout__body {
  padding: 0 !important;
}

.navigation-link {
  white-space: nowrap;
  color: var(--tertiary-100);
  cursor: pointer;
  margin: 0 !important;
  transition: 0.2s ease-in;
}

.navigation-link:hover {
  color: var(--tertiary-100);
  opacity: 0.5;
  transition: 0.2s ease-out;
}

.select-table {
  margin: 5px 0 0 0;
}

.notification {
  border-radius: 13px !important;
  box-shadow: 0 4px 4px rgba(82, 79, 78, 0.24) !important;
  padding: 16px 50px 16px 16px !important;

  &InfoIcon {
    width: 24px;
  }

  &Success {
    background-color: var(--success) !important;
  }

  &Warning {
    background-color: var(--primary-100) !important;
  }

  &Error {
    background-color: var(--tertiary-100) !important;
  }

  &CloseIcon {
    width: 35px;
  }

  ul {
    margin: 0;
  }

  &Small {
    padding: 13px !important;
    width: fit-content !important;

    .ant-notification-notice-message {
      margin: 0 !important;
    }
  }
}

.cart-card {
  width: 100%;
}

.ant-space-item .cart-card:first-of-type hr {
  display: none;
}

.flex {
  display: flex;
}

hr {
  margin: 0;
}

summary::-webkit-details-marker {
  display: none;
}

.errorMessage {
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 16px;
  height: 16px;
  margin: 0;
  color: var(--tertiary-100);
}

.ant-pagination.ant-pagination-mini .ant-pagination-prev {
  @include device(extraMediumSmall) {
    font-size: 14px;
    margin-right: 2px !important;
  }
}

.ant-pagination .ant-pagination-prev {
  margin: 0;
}

.ant-pagination.ant-pagination-mini .ant-pagination-next {
  @include device(extraMediumSmall) {
    font-size: 14px;
    margin-left: 2px !important;
  }
}

.ant-pagination-item {
  @include device(extraMediumSmall) {
    width: 28px !important;
    height: 32px !important;
    line-height: 28px !important;
    font-size: 14px !important;
  }
}

.ant-table-pagination-right {
  @include device(extraMediumSmall) {
    justify-content: center !important;
  }
}

.signature-label {
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 20px;
  color: var(--dark-06);
  margin-top: 0;
}

.img-crop-modal {
  .ant-modal-content {
    min-width: 600px;
    border-radius: 16px !important;
    border-radius: 16px !important;
    @media screen {
      @media (max-width: 779px) {
        min-width: 100%;
        width: calc(100vw - 20px) !important;
      }
    }
  }

  .ant-modal-header {
    padding: 16px 32px 0px 32px !important;
    border-bottom: none !important;
    border-top-right-radius: 16px !important;
    border-top-left-radius: 16px !important;

    .ant-modal-title {
      font-style: normal;
      font-weight: 600;
      font-size: 24px !important;
      line-height: 32px;
      margin: 0;
    }
  }

  .ant-modal-footer {
    padding: 0 32px 24px 32px !important;
  }

  .ant-modal-body {
    padding: 16px 32px 0 !important;
  }

  .img-crop-container {
    border-radius: 6px;
  }

  .img-crop-control {
    width: 85%;
  }

  .ant-modal-footer,
  .ant-modal-header {
    border: none;
    display: block !important;
  }

  .ant-btn-default {
    border-radius: 6px;
    border: 1px solid var(--primary-100) !important;
    color: var(--secondary-100) !important;
  }

  .ant-btn-primary {
    border-radius: 6px;
    border-color: transparent !important;
    background-color: var(--primary-100) !important;
  }

  .ant-slider-track {
    background-color: var(--primary-100) !important;
  }

  .ant-slider-handle {
    border: 2px solid var(--primary-100) !important;
  }
}

.ant-input-affix-wrapper {
  border: none !important;
  height: 100%;
  padding: 0 11px 0 0 !important;
}

.center {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}

.hide {
  display: none !important;
}

.w-full {
  width: 100% !important;
}

.items-start {
  align-items: start !important;
}

.disable-scrollbars::-webkit-scrollbar {
  background: transparent !important;
  width: 0px !important;
}

.disable-scrollbars {
  scrollbar-width: none !important;
  -ms-overflow-style: none !important;
}

.customLink {
  color: var(--tertiary-100) !important;
  cursor: pointer;
  margin: 0 !important;

  &__active {
    color: var(--success) !important;

    &:hover {
      color: var(--success) !important;
      opacity: 0.5;
    }
  }

  &:hover {
    opacity: 0.5;
    color: var(--tertiary-100) !important;
  }
}

.page-title {
  margin: 0 0 12px 0;
}

.hidden {
  visibility: hidden !important;
}

.ant-modal-wrap {
  overflow-x: hidden !important;
}

.none {
  display: none !important;
}
